import * as React from "react";
import { Icon } from "./Icon.styles";
interface Props {
  iconName: string;
  size?: string;
  className?: string;
}

export default ({ iconName, size, className }: Props) => (
  <Icon size={size} className={`${className} icon-${iconName}`} />
);
