import { css } from "styled-components";

import { device } from "./media";

const color = {
  black: "#000000",
  white: "#ffffff",
  grey1: "#f5f5f5",
  grey2: "#e0e0e0",
  grey3: "#9e9e9e",
  grey4: "#616161",
  grey5: "#212121",
  grey6: "#e9e9e9",
  primary1: "#ff5f4e",
  primary2: "#ed1c24",
  primary3: "#b20000",
  error: "#d32f2f",
  incomplete: "#ffc107",
  confirmation: "#4caf50",
};

const gridValues = {
  sm: {
    gutter: 1, // 16px
    padding: 1,
    maxWidth: "100%",
  },
  md: {
    gutter: 1,
    padding: 1,
    maxWidth: "100%",
  },
  lg: {
    gutter: 1.5, // 24px
    padding: 1.5,
    maxWidth: "78rem", // 1248px
  },
};

const gridStyles: any = {
  getContainerStyles: () => {
    return css`
      width: 100%;
      padding-left: ${gridValues.sm.padding}rem;
      padding-right: ${gridValues.sm.padding}rem;
      box-sizing: border-box;

      @media ${device.lg} {
        max-width: ${gridValues.lg.maxWidth};
        padding-left: ${gridValues.lg.padding}rem;
        padding-right: ${gridValues.lg.padding}rem;
        margin-left: auto;
        margin-right: auto;
      }
    `;
  },
  getRowStyles: () => {
    return css`
      display: flex;
      flex-wrap: wrap;
      margin-left: ${0 - gridValues.sm.gutter / 2}rem;
      margin-right: ${0 - gridValues.sm.gutter / 2}rem;

      @media ${device.lg} {
        margin-left: ${0 - gridValues.lg.gutter / 2}rem;
        margin-right: ${0 - gridValues.lg.gutter / 2}rem;
      }
    `;
  },
  getColStyles: (
    sm = 12,
    md = 12,
    lg = 12,
    offsetSm = 0,
    offsetMd = 0,
    offsetLg = 0,
    totalSm = 12,
    totalMd = 12,
    totalLg = 12,
  ) => {
    return css`
      box-sizing: border-box;
      padding-left: ${gridValues.sm.gutter / 2}rem;
      padding-right: ${gridValues.sm.gutter / 2}rem;
      flex-basis: ${100 / (totalSm / sm)}%;
      max-width: ${100 / (totalSm / sm)}%;
      margin-left: ${100 / (totalSm / offsetSm)}%;

      @media ${device.md} {
        flex-basis: ${100 / (totalMd / md)}%;
        max-width: ${100 / (totalMd / md)}%;
        margin-left: ${100 / (totalMd / offsetMd)}%;
      }

      @media ${device.lg} {
        padding-left: ${gridValues.lg.gutter / 2}rem;
        padding-right: ${gridValues.lg.gutter / 2}rem;
        flex-basis: ${100 / (totalLg / lg)}%;
        max-width: ${100 / (totalLg / lg)}%;
        margin-left: ${100 / (totalLg / offsetLg)}%;
      }
    `;
  },
};

const zIndex = {};

const font = [`'Fira Sans', sans-serif`];

const fontWeight = {
  regular: 400,
  bold: 700,
};

const superText = {
  sm: {
    size: "50px",
    lineHeight: "72px",
  },
  md: {
    size: "110px",
    lineHeight: "158px",
  },
  lg: {
    size: "200px",
    lineHeight: "288px",
  },
};

const h1 = {
  sm: {
    size: "40px",
    lineHeight: "50px",
  },
  md: {
    size: "46px",
    lineHeight: "58px",
  },
  lg: {
    size: "62px",
    lineHeight: "76px",
  },
  fontWeight: fontWeight.bold,
};

const h2 = {
  sm: {
    size: "36px",
    lineHeight: "46px",
  },
  md: {
    size: "40px",
    lineHeight: "54px",
  },
  lg: {
    size: "46px",
    lineHeight: "62px",
  },
  fontWeight: fontWeight.bold,
};

const h3Bold = {
  sm: {
    size: "22px",
    lineHeight: "28px",
  },
  md: {
    size: "22px",
    lineHeight: "28px",
  },
  lg: {
    size: "26px",
    lineHeight: "34px",
  },
  fontWeight: fontWeight.bold,
};

const h3Regular = {
  sm: {
    size: "22px",
    lineHeight: "28px",
  },
  md: {
    size: "22px",
    lineHeight: "28px",
  },
  lg: {
    size: "26px",
    lineHeight: "34px",
  },
  fontWeight: fontWeight.regular,
};

const h4 = {
  sm: {
    size: "16px",
    lineHeight: "20px",
  },
  md: {
    size: "16px",
    lineHeight: "20px",
  },
  lg: {
    size: "18px",
    lineHeight: "22px",
  },
  fontWeight: fontWeight.bold,
};

const body1 = {
  size: "18px",
  lineHeight: "28px",
  fontWeight: fontWeight.regular,
};

const body2 = {
  size: "16px",
  lineHeight: "22px",
  fontWeight: fontWeight.regular,
};

const button = {
  size: "14px",
  lineHeight: "16px",
  letterSpacing: "0.75px",
  fontWeight: fontWeight.regular,
};

const overline = {
  size: "10px",
  lineHeight: "16px",
  letterSpacing: "1.5px",
  fontWeight: fontWeight.regular,
};

const theme = {
  gridValues,
  gridStyles,
  h1,
  h2,
  h3Bold,
  h3Regular,
  h4,
  body1,
  body2,
  button,
  overline,
  color,
  font,
  fontWeight,
  superText,
  zIndex,
};

export default theme;
