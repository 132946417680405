import styled from "styled-components";
import Icon from "./components/icon/Icon";
import { device } from "./styles/media";

export const Home = styled.div`
  ${props => props.theme.gridStyles.getContainerStyles()}
`;

export const Row = styled.div`
  ${props => props.theme.gridStyles.getRowStyles()};
`;

export const StyledImage = styled.img`
  ${props => props.theme.gridStyles.getColStyles(12, 6, 6)}
`;

export const Title = styled.h1`
  text-align: center;
`;

export const Col = styled.div`
  ${props => props.theme.gridStyles.getColStyles(12, 12, 12)}
  text-align: center;
`;

export const StyledIcon = styled(Icon)`
  padding: 0.5rem;
`;

export const ImageWrapper = styled.div`
  padding: 0.5rem;

  @media ${device.md} {
    padding: 1rem;
  }
`;

export const IconWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const Text = styled.p`
  font-size: 16px;
`;

export const IconLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.black};
  position: relative;

  &:hover {
    &:after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 0;
      border-top: 5px solid ${props => props.theme.color.primary2};
    }
  }
`;
