import { createGlobalStyle } from "styled-components";
import { normalize } from "styled-normalize";

const GlobalStyles = createGlobalStyle`
  ${normalize}

  @font-face {
    font-family: 'icomoon';
    src:  url("/fonts/icomoon.eot?e47pkw");
    src:  url("/fonts/icomoon.eot?e47pkw#iefix") format('embedded-opentype'),
      url("/fonts/icomoon.ttf?e47pkw") format('truetype'),
      url("/fonts/icomoon.woff?e47pkw") format('woff'),
      url("/fonts/icomoon.svg?e47pkw#icomoon") format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: block;
  }

  body {  
    -webkit-font-smoothing: antialiased;
    font-family: 'Fira Sans', sans-serif;
  }
`;

export default GlobalStyles;
