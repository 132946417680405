import React from "react";
import GlobalStyles from "./styles/GlobalStyles";

import {
  Home,
  StyledImage,
  Row,
  Col,
  StyledIcon,
  IconLink,
  IconWrapper,
  ImageWrapper,
  Text
} from "./App.styles";

import logo from "./assets/images/livity-logo.svg";

export default () => (
  <Home>
    <GlobalStyles />
    <Row>
      <Col>
        <ImageWrapper>
          <StyledImage src={logo} />
        </ImageWrapper>
        <IconWrapper>
          <IconLink
            target="_blank"
            href="https://www.instagram.com/livity_sound/"
          >
            <StyledIcon iconName="instagram" size="28px" />
          </IconLink>
          <IconLink
            target="_blank"
            href="https://www.facebook.com/Livity-Sound-1625281891027905"
          >
            <StyledIcon iconName="facebook" size="28px" />
          </IconLink>
          <IconLink
            target="_blank"
            href="https://www.discogs.com/label/251117-Livity-Sound"
          >
            <StyledIcon iconName="discogs" size="28px" />
          </IconLink>
          <IconLink target="_blank" href="https://twitter.com/livity_sound">
            <StyledIcon iconName="twitter" size="28px" />
          </IconLink>
          <IconLink target="_blank" href="https://livitysound.bandcamp.com/">
            <StyledIcon iconName="bandcamp" size="28px" />
          </IconLink>
          <IconLink target="_blank" href="mailto:hello@livitysound.net">
            <StyledIcon iconName="envelop" size="28px" />
          </IconLink>
        </IconWrapper>
        <Text>Full site coming soon</Text>
      </Col>
    </Row>
  </Home>
);
